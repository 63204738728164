/* eslint-disable camelcase */
/* eslint-disable max-lines */

import { handleElementVisibility, keepAppointmentSlotReservationAlive, useStorage } from './helpers.js';

const { $, book: { stepId, bubbleMessages, urlParams, nextStepUrl } } = window;
const maxIdleTimeMin = 5;
const inactiveClientInterval = 60000;
const keepAppointmentSlotReservationAliveInterval = 25000;
const uniqueId = '_' + (new Date).getTime() + '.' + Math.random().toString(36).substring(5);
let currBubbleMessage = 0;
let idleTimeMin = 0;

if (stepId < 5) {
    stepId === 1 && useStorage().get('checkout') && useStorage().clear();

    !useStorage().get('session') && useStorage().set('session', uniqueId);

    if (stepId > 1 && useStorage().get('checkout')) {
        useStorage().clear();

        window.location = '/';
    }

    setInterval(inactiveClient, inactiveClientInterval);

    setInterval(
        keepAppointmentSlotReservationAlive,
        keepAppointmentSlotReservationAliveInterval,
    );
}

function changeBubbleText() {
    $('.js-bubble').html(bubbleMessages[currBubbleMessage]);

    currBubbleMessage++;
}

function inactiveClient() {
    const currentSlot = {
        date: useStorage().get('date'),
        provider: useStorage().get('provider'),
        slot: useStorage().get('slot'),
    };

    if (currentSlot && currentSlot.slot && currentSlot.date && currentSlot.provider) {
        idleTimeMin = idleTimeMin + 1;

        if (idleTimeMin > maxIdleTimeMin) {
            // Clear storage
            useStorage().clear();

            // Redirect to start
            useStorage().set('error', 'expired');

            window.location = '/';
        }
    }
}

function populateDOMwithData() {
    const datetime = useStorage().get('datetime') || '-';
    const make = useStorage().get('make_key') || '-';
    const model = useStorage().get('model_key') || '-';
    const ownerFullName = useStorage().get('owner[fullname]') || '-';
    const ownerPhone = useStorage().get('owner[phone]') || '-';
    const ownerAddress = useStorage().get('owner[address]') || '-';

    $('.storage-data-datetime').html(datetime);
    $('.storage-data-make').html(make).css('text-transform', 'capitalize');
    $('.storage-data-model').html(model).css('text-transform', 'capitalize');
    $('.storage-data-owner-name').html(ownerFullName);
    $('.storage-data-owner-phone').html(ownerPhone);
    $('.storage-data-owner-address').html(ownerAddress);
}

function populateStorageFromUrl() {
    if (urlParams) {
        $.each(urlParams, function(key, value) {
            !useStorage().get(key) && useStorage().set(key, value);
        });
    }
}

$(document).ready(function() {
    populateDOMwithData();

    populateStorageFromUrl();

    $(document).on('mousemove keypress', function() {
        idleTimeMin = 0;
    });

    // Populates all inputs from session storage
    $('[name]').each(function() {
        const name = $(this).attr('name');
        const value = useStorage().get(name);

        if (!value) {
            return;
        }

        if ($(this).is(':checkbox')) {
            value == 'true' && $(this).prop('checked', true);
        } else if ($(this).is(':radio')) {
            $(this).val() == value && $(this).prop('checked', true);
        } else {
            $(this).val(value).trigger('change');
        }
    });

    // Saves all `name` selectors in storage
    $(document).on('keyup onmouseout keydown keypress blur change', '[name]', function() {
        const name = $(this).attr('name');
        let value = $(this).val();

        if (name === 'invoice[zipcode]') {
            value = value.replace(/\D/g, '');
        }

        if (!name || name === '') {
            return;
        }

        if ($(this).is(':checkbox')) {
            $(this).is(':checked') ? useStorage().set(name, value) : useStorage().remove(name);
        } else {
            value === '' ? useStorage().remove(name) : useStorage().set(name, value);
        }

        populateDOMwithData();
    });

    // Resets errors
    $(document).on('change', '[name]', function() {
        const name = $(this).attr('name');

        if ($(`[name="${name}"]`).hasClass('error')) {
            $(`[name="${name}"]`).removeClass('error');
        }

        handleElementVisibility(`span.field-error[data-error="${name}"]`, 'hide');
    });

    if (useStorage().get('error')) {
        const error = useStorage().get('error');

        useStorage().remove('error');

        if (error === 'expired') {
            handleElementVisibility('.error-expired', 'show');
        }
    }

    // Progress Bar
    if ($('[data-step]')) {
        $('.js-steps').addClass('step--' + $('[data-step]').data('step'));
    }

    // Popups
    $('.open-popup-link').magnificPopup({
        mainClass: 'mfp-fade',
        midClick: true,
        removalDelay: 300,
        type: 'inline',
    });

    // Change Bubble text on load
    if ($('.js-bubble').length > 0) {
        changeBubbleText();
    }

    // Button submit form
    $('.js-btn-submit').click(function(e) {
        e.preventDefault();

        $(this).closest('form').submit();
    });

    // Next Step Button
    $('.btn-next-step').click(function(e) {
        e.preventDefault();

        window.location.href = nextStepUrl;
    });
});

jQuery.fn.extend({
    disable: function(state) {
        return this.each(function() {
            this.disabled = state;
        });
    },
});
